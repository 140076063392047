import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './App.css';
import logo from './AHP_Logo_MT.png';

const getDateInOneYear = () => {
    var d = new Date();
    d.setFullYear(d.getFullYear() + 1);
    return d;
}


function App() {


    const TESTSYS = false;
    const BASEURL = TESTSYS ? "https://kanubuchung.a-h-p.de/test/api/" : "https://kanubuchung.a-h-p.de/api/";
    const MODE = 'USER'; // 'USER' or 'ADMIN'
    const isMobile = window.matchMedia("only screen and (max-width: 800px)").matches;

    const POSSIBLE_DATE_ALTERNATIVES = [-1 * 7, -2, -1, 1, 2, 1 * 7];
    const MAX_KANADIER = 5;
    const MAX_KAJAKS = 2;
    const MAX_SUPS = 6;
    const VERBAENDE = [{short: 'BdP', long: 'Bund der Pfadfinderinnen und Pfadfinder'},
        {short: 'CGP', long: 'Bund Christlicher Gemeinde-Pfadfinder'},
        {short: 'CPA', long: 'Christlichen Pfadfinderinnen und Pfadfinder der Adventjugend'},
        {short: 'CPD', long: 'Christliche Pfadfinderschaft Deutschlands'},
        {short: 'DPBH', long: 'Deutscher Pfadfinderbund Hamburg'},
        {short: 'DPSG', long: 'Deutsche Pfadfinderschaft St. Georg'},
        {short: 'PBN', long: 'Pfadfinder*innenbund Nord'},
        {short: 'PBNL', long: 'Pfadfinder & Pfadfinderinnenbund Nordlicht'},
        {short: 'PdF', long: 'Pfadfinderschaft der FEG'},
        {short: 'RR', long: 'Royal Rangers'},
        {short: 'VCP', long: 'Verband Christlicher Pfadfinderinnen und Pfadfinder'}];


    //toISOString would return the string without timezone
    const todayIso = new Date().toLocaleString('sv').substr(0, 10);
    const todayPlusOneYearIso = getDateInOneYear().toLocaleString('sv').substr(0, 10);
    const datumRegexpIso = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/;
    const datumRegexpNat = /^\b(0?[1-9]|[12][0-9]|3[01])\b\.(0?[1-9]|1[012])\.\d{4}$/;
    const phoneChar = new Set([" ", "+", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]);

    const [datumCheckResult, setDatumCheckResult] = useState();
    const [datumCheckAlternatives, setDatumCheckAlternatives] = useState();

    const ls = localStorage;

    const [multidayBooking, setMultidayBooking] = useState(false);
    const [datumVon, setDatumVon] = useState("");
    const [datumBis, setDatumBis] = useState("");

    const [datum, setDatum] = useState("");
    const [beginnTsp, setBeginnTsp] = useState("");
    const [endeTsp, setEndeTsp] = useState("");
    const [vorname, setVorname] = useState("");
    const [fahrtenname, setFahrtenname] = useState("");
    const [nachname, setNachname] = useState("");
    const [mail, setMail] = useState("");
    const [handy, setHandy] = useState("+49 1");
    const [verband, setVerband] = useState("");
    const [verbandName, setVerbandName] = useState("");
    const [gruppe, setGruppe] = useState("");
    const [anzahlKanadier, setAnzahlKanadier] = useState("0");
    const [anzahlKajaks, setAnzahlKajaks] = useState("0");
    const [anzahlSups, setAnzahlSups] = useState("0");
    const [anmerkungen, setAnmerkungen] = useState("");
    const [verteiler, setVerteiler] = useState(false);
    const [bookingStatus, setBookingStatus] = useState('pending');

    const [code, setCode] = useState("");
    const [id, setId] = useState("");
    const [errors, setErrors] = useState([]);
    const [errorInputIds, setErrorInputIds] = useState([]);
    const [status, setStatus] = useState(1);
    const [codeError, setCodeError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [backendErrorOnStatus2, setBackendErrorOnStatus2] = useState(undefined);
    const [backendErrorCountOnStatus3, setBackendErrorCountOnStatus3] = useState(0);

    useEffect(() => {
        const fromLs = localStorage.getItem("username");
        if (fromLs) {
            axios.defaults.params = {};
            axios.defaults.params['user'] = fromLs;
        }
    }, []);

    const resetErrors = () => {
        setErrors([]);
    };

    const reportBackendError = (error) => {
        // const msg = error.message;
        // debugger;
        // axios.post(BASEURL + 'reportError.php', {
        //     message: error.message,
        //     data: error.data,
        // });
    };

    function validateDatum() {
        var arr = [];
        if (multidayBooking) {
            !datumVon && arr.push({inputId: 'datumVon', text: "Bitte wähle ein Von-Datum."});
            !datumBis && arr.push({inputId: 'datumBis', text: "Bitte wähle ein Bis-Datum."});
            datumVon && !(datumRegexpIso.exec(datumVon) || datumRegexpNat.exec(datumVon)) && arr.push({
                inputId: 'datumVon',
                text: "Bitte gib das Von-Datum im Format dd.mm.yyyy ein, z.B. \"20.4.2022\" ."
            });
            datumVon && (datumRegexpIso.exec(datumVon) || datumRegexpNat.exec(datumVon)) && datumConvBackend(datumVon).localeCompare(todayIso) < 0 && arr.push({
                inputId: 'datumVon',
                text: "Bitte wähle ein Von-Datum, welches nicht in der Vergangenheit liegt."
            });
            datumVon && (datumRegexpIso.exec(datumVon) || datumRegexpNat.exec(datumVon)) && datumConvBackend(datumVon).localeCompare(todayPlusOneYearIso) > 0 && arr.push({
                inputId: 'datumVon',
                text: "Bitte wähle ein Von-Datum, welches nicht mehr als ein Jahr in der Zukunft liegt."
            });

            datumBis && !(datumRegexpIso.exec(datumBis) || datumRegexpNat.exec(datumBis)) && arr.push({
                inputId: 'datumBis',
                text: "Bitte gib das Bis-Datum im Format dd.mm.yyyy ein, z.B. \"20.4.2021\" ."
            });
            datumBis && (datumRegexpIso.exec(datumBis) || datumRegexpNat.exec(datumBis)) && datumConvBackend(datumBis).localeCompare(todayIso) < 0 && arr.push({
                inputId: 'datumBis',
                text: "Bitte wähle ein Bis-Datum, welches nicht in der Vergangenheit liegt."
            });
            datumBis && (datumRegexpIso.exec(datumBis) || datumRegexpNat.exec(datumBis)) && datumConvBackend(datumBis).localeCompare(todayPlusOneYearIso) > 0 && arr.push({
                inputId: 'datumBis',
                text: "Bitte wähle ein Bis-Datum, welches nicht mehr als ein Jahr in der Zukunft liegt."
            });
        } else {
            !datum && arr.push({inputId: 'datum', text: "Bitte wähle ein Datum."});
            datum && !(datumRegexpIso.exec(datum) || datumRegexpNat.exec(datum)) && arr.push({
                inputId: 'datum',
                text: "Bitte gib das Datum im Format dd.mm.yyyy ein, z.B. \"20.4.2021\" ."
            });
            datum && (datumRegexpIso.exec(datum) || datumRegexpNat.exec(datum)) && datumConvBackend(datum).localeCompare(todayIso) < 0 && arr.push({
                inputId: 'datum',
                text: "Bitte wähle ein Datum, welches nicht in der Vergangenheit liegt."
            });
            datum && (datumRegexpIso.exec(datum) || datumRegexpNat.exec(datum)) && datumConvBackend(datum).localeCompare(todayPlusOneYearIso) > 0 && arr.push({
                inputId: 'datum',
                text: "Bitte wähle ein Datum, welches nicht mehr als ein Jahr in der Zukunft liegt."
            });
        }
        return arr;
    }

    function validateInputs(blurEvent) {
        let arr = validateDatum();

        !vorname && arr.push({inputId: 'vorname', text: "Bitte gib deinen Vornamen ein."});
        !nachname && arr.push({inputId: 'nachname', text: "Bitte gib deinen Nachnamen ein."});
        !mail && arr.push({inputId: 'mail', text: "Bitte gib deine E-Mail-Adresse ein."});
        mail && !validateEmail(mail) && arr.push({inputId: 'mail', text: "Bitte gib eine valide E-Mail ein."});
        !handy && arr.push({inputId: 'handy', text: "Bitte gib deine Handynummer ein."});
        handy && handy.trim().localeCompare("+49") === 0 && arr.push({
            inputId: 'handy',
            text: "Bitte gib deine Handynummer ein."
        });
        handy && ((handy.trim().localeCompare("+49 1") === 0) || !(handy.replaceAll("+49", "").trim().startsWith("1"))) && arr.push({
            inputId: 'handy',
            text: "Bitte gib eine Handynummer ein. Da die Nummer per SMS bestätigt werden muss, wird Festnetz nicht unterstützt."
        });
        verband === "" && arr.push({inputId: 'verband', text: "Bitte wähle deinen Verband aus."});
        verband === "Andere" && verbandName.trim() === "" && arr.push({
            inputId: 'verbandName',
            text: "Bitte gib den Namen deines Verbands ein."
        });
        anzahlKanadier === "0" && anzahlKajaks === "0" && anzahlSups === "0" && arr.push({text: "Bitte wähle aus, welche Boote du leihen möchtest."});

        const allErrorInputIds = [...new Set(arr.map((error) => error.inputId))].filter((elem) => elem !== undefined);
        if (blurEvent) {
            if (blurEvent === true) {
                setErrors(arr);
                setErrorInputIds(allErrorInputIds);
            } else if (allErrorInputIds.includes(blurEvent.target.id)) {
                setErrorInputIds((prevState => [...new Set([...prevState, blurEvent.target.id])]));
            } else {
                setErrorInputIds((prevState => prevState.filter((elem) => elem !== blurEvent.target.id)));
            }
        }
        return arr.length === 0;
    }

    useEffect(() => {
        // eslint-disable-next-line default-case
        switch (MODE) {
            case 'ADMIN':
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);

                setDatum(urlParams.get("datum") ? urlParams.get("datum") : datum);
                setBeginnTsp(urlParams.get("beginnTsp") ? urlParams.get("beginnTsp") : beginnTsp);
                setEndeTsp(urlParams.get("endeTsp") ? urlParams.get("endeTsp") : endeTsp);
                setVorname(urlParams.get("vorname") ? urlParams.get("vorname") : vorname);
                setFahrtenname(urlParams.get("fahrtenname") ? urlParams.get("fahrtenname") : fahrtenname);
                setNachname(urlParams.get("nachname") ? urlParams.get("nachname") : nachname);
                setMail(urlParams.get("mail") ? urlParams.get("mail") : mail);
                setHandy(urlParams.get("handy") ? urlParams.get("handy") : handy);
                setVerband(urlParams.get("verband") ? (VERBAENDE.includes(urlParams.get("verband")) ? urlParams.get("verband") : "Andere") : verband);
                setVerbandName(VERBAENDE.includes(urlParams.get("verband")) ? "" : urlParams.get("verband") ? urlParams.get("verband") : verbandName);
                setGruppe(urlParams.get("gruppe") ? urlParams.get("gruppe") : gruppe);
                setAnzahlKanadier(urlParams.get("anzahlKanadier") ? urlParams.get("anzahlKanadier") : anzahlKanadier);
                setAnzahlKajaks(urlParams.get("anzahlKajaks") ? urlParams.get("anzahlKajaks") : anzahlKajaks);
                setAnzahlSups(urlParams.get("anzahlSups") ? urlParams.get("anzahlSups") : anzahlSups);
                setAnmerkungen(urlParams.get("anmerkungen") ? urlParams.get("anmerkungen") : anmerkungen);

                break;
            case 'USER':
                setDatum(ls.getItem("datum") ? ls.getItem("datum") : datum);
                setBeginnTsp(ls.getItem("beginnTsp") ? ls.getItem("beginnTsp") : beginnTsp);
                setEndeTsp(ls.getItem("endeTsp") ? ls.getItem("endeTsp") : endeTsp);
                setVorname(ls.getItem("vorname") ? ls.getItem("vorname") : vorname);
                setFahrtenname(ls.getItem("fahrtenname") ? ls.getItem("fahrtenname") : fahrtenname);
                setNachname(ls.getItem("nachname") ? ls.getItem("nachname") : nachname);
                setMail(ls.getItem("mail") ? ls.getItem("mail") : mail);
                setHandy(ls.getItem("handy") ? ls.getItem("handy") : handy);
                setVerband(ls.getItem("verband") ? (VERBAENDE.includes(ls.getItem("verband")) ? ls.getItem("verband") : "Andere") : verband);
                setVerbandName(VERBAENDE.includes(ls.getItem("verband")) ? "" : ls.getItem("verband") ? ls.getItem("verband") : verbandName);
                setGruppe(ls.getItem("gruppe") ? ls.getItem("gruppe") : gruppe);
                setAnzahlKanadier(ls.getItem("anzahlKanadier") ? ls.getItem("anzahlKanadier") : anzahlKanadier);
                setAnzahlKajaks(ls.getItem("anzahlKajaks") ? ls.getItem("anzahlKajaks") : anzahlKajaks);
                setAnzahlSups(ls.getItem("anzahlSups") ? ls.getItem("anzahlSups") : anzahlSups);
                setAnmerkungen(ls.getItem("anmerkungen") ? ls.getItem("anmerkungen") : anmerkungen);
                break;
        }
    }, []);

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const getPossibleDateAlternatives = (datum) => {
        let alternatives = [];

        POSSIBLE_DATE_ALTERNATIVES.forEach((numberOfDays) => {
            let date = new Date(datum);
            date.setDate(date.getDate() + numberOfDays);
            let iso = date.toISOString().substr(0, 10);
            if (iso.localeCompare(todayIso) > 0)
                alternatives.push(iso);
        });

        return alternatives;
    }

    const checkDatum = () => {
        setDatumCheckResult(undefined);
        if (!datum) {
            return;
        }
        if (datum && (datumRegexpIso.exec(datum) || datumRegexpNat.exec(datum)) && datumConvBackend(datum).localeCompare(todayPlusOneYearIso) <= 0) {
            let dates = [datumConvBackend(datum)].concat(getPossibleDateAlternatives(datum));

            axios.get(BASEURL + "checkDates.php", {params: {dates: JSON.stringify(dates)}}).then((resp) => {
                setDatumCheckResult(resp.data[datumConvBackend(datum)]);

                let withoutSelectedDate = resp.data;
                delete withoutSelectedDate[datumConvBackend(datum)];
                setDatumCheckAlternatives(withoutSelectedDate);
            });
        }
    }

    const fancyDatum = (input) => {
        if (datumRegexpIso.exec(input)) {
            var parts = input.split("-");
            return parts[2] + "." + parts[1] + "." + parts[0];
        } else
            return input;
    }

    const datumNatToIso = (nat) => {
        return nat.split('.').reverse().map((elem) => {
            if (elem.length >= 2)
                return elem;
            else
                return "0" + elem;
        }).join('-');
    }

    const datumConvBackend = (input) => {
        if (datumRegexpIso.exec(input.trim())) {
            return input.trim();
        } else {
            return datumNatToIso(input.trim());
        }
    }

    const saveToLocalStorage = () => {
        ls.setItem("vorname", vorname.trim());
        ls.setItem("fahrtenname", fahrtenname.trim());
        ls.setItem("nachname", nachname.trim());
        ls.setItem("mail", mail.trim());
        ls.setItem("verband", verband === "Andere" ? verbandName : verband);
        ls.setItem("handy", handy.trim());
        ls.setItem("gruppe", gruppe.trim());
    }

    const flipMultidayBooking = () => {
        const newMultidayBooking = !multidayBooking;
        setMultidayBooking(newMultidayBooking);
        if (newMultidayBooking) {
            setBeginnTsp("");
            setEndeTsp("");
            if (!datumVon && datum) {
                setDatumVon(datum);
            }
            setDatum("");
        }
    };

    useEffect(() => {
        checkDatum();
    }, [datum]);

    return (
        <div className="kanubuchung">
            <a href="https://www.a-h-p.de"><img src={logo} width="260px" height="135px" alt=""/></a>
            <h1>Kanubuchung</h1>
            {status === 1 && <>
                <div className="pflichtfeld-hinweis"><p>Mit einem Sternchen (*) markierte Felder sind Pflichtfelder.</p>
                </div>
                {MODE === 'ADMIN' && <>
                    <label htmlFor="vorlage">Vorlage</label>
                    <select id="vorlage" name="vorlage" onChange={(e) => {
                        switch (e.target.value) {
                            case "":
                                setMail("");
                                setFahrtenname("");
                                setVorname("");
                                setNachname("");
                                setVerband("");
                                setVerbandName("");
                                setHandy("+49");
                                break;
                            case "blocker":
                                setMail("kanubuchung@a-h-p.de");
                                setFahrtenname("");
                                setVorname("Blocker");
                                setNachname("Blocker");
                                setVerband("Andere");
                                setVerbandName("-");
                                setHandy("+49 123");
                                setBookingStatus('approved-nomails');
                                break;
                            case "verb":
                                setMail("verb@a-h-p.de");
                                setFahrtenname("Verb");
                                setVorname("Verb");
                                setNachname("Verb");
                                setVerband("Andere");
                                setVerbandName("AHP");
                                setHandy("+49151504604/23");
                                setBookingStatus('approved-nomails');
                                break;
                            case "jakob":
                                setMail("jakob@a-h-p.de");
                                setFahrtenname("");
                                setVorname("Jakob");
                                setNachname("Grzeskowiak");
                                setVerband("Andere");
                                setVerbandName("AHP");
                                setHandy("+491749947112");
                                setBookingStatus('approved-nomails');
                                break;
                            default:
                                break;
                        }
                    }}>
                        <option value="">Keine</option>
                        <option value="blocker">Blocker</option>
                        <option value="verb">Verb</option>
                        <option value="jakob">Jakob</option>
                    </select>
                </>}
                <label htmlFor="multidayBooking">Mehrtägige Buchung</label>
                <input type="checkbox" id="multidayBooking" value={multidayBooking}
                       onChange={flipMultidayBooking}/>

                {multidayBooking && <>
                    <label htmlFor="datumVon">Datum von*</label>
                    <input type="date" value={datumVon} id="datumVon"
                           name="datumVon"
                           className={errorInputIds.includes('datumVon') ? 'validation-error' : undefined}
                           onBlur={validateInputs}
                           placeholder="z.B. 20.4.2021"
                           min={todayIso}
                           max={todayPlusOneYearIso}
                           onChange={e => {
                               resetErrors();
                               setDatumVon(e.target.value);
                           }}/>

                    <label htmlFor="datumBis">Datum bis*</label>
                    <input type="date" value={datumBis} id="datumBis"
                           name="datumBis"
                           className={errorInputIds.includes('datumBis') ? 'validation-error' : undefined}
                           onBlur={validateInputs}
                           placeholder="z.B. 20.4.2021"
                           min={todayIso}
                           max={todayPlusOneYearIso}
                           onChange={e => {
                               resetErrors();
                               setDatumBis(e.target.value);
                           }}/>
                </>}

                {!multidayBooking && <>
                    <label htmlFor="datum">Datum*</label>
                    <input type="date" value={datum} id="datum"
                           name="datum"
                           className={errorInputIds.includes('datum') ? 'validation-error' : undefined}
                           onBlur={validateInputs}
                           placeholder="z.B. 20.4.2021"
                           min={todayIso}
                           max={todayPlusOneYearIso}
                           onChange={e => {
                               resetErrors();
                               setDatum(e.target.value);
                           }}/>
                    <label htmlFor="beginnTsp">Zeitraum der Ausleihe (falls schon bekannt)</label>
                    <input type="time" value={beginnTsp} id="beginnTsp" name="beginnTsp"
                           onChange={e => {
                               setBeginnTsp(e.target.value)
                           }}/>
                    {" bis "}
                    <input type="time" value={endeTsp} id="endeTsp" name="endeTsp" onChange={e => {
                        setEndeTsp(e.target.value)
                    }}/>
                </>}


                {/*datumCheckResult && datumCheckResult.concurrentBookingExists === false &&
                <p id="datum-hinweis"> ✅️ An diesem Tag gibt es bisher noch keine Buchung.</p>}
                {datumCheckResult && datumCheckResult.concurrentBookingExists === true &&
                <p id="datum-hinweis"> ⚠️ variant="contained"An diesem Tag gibt es schon mindestens eine Buchung. Bitte gib einen
                    Zeitraum an, damit wir besser planen können.</p>*/}


                <label htmlFor="vorname">Vorname*</label>
                <input type="text" value={vorname} id="vorname" name="vorname"
                       className={errorInputIds.includes('vorname') ? 'validation-error' : undefined}
                       onBlur={validateInputs}
                       onChange={e => {
                           resetErrors();
                           setVorname(e.target.value)
                       }}/>

                <label htmlFor="fahrtenname">Fahrtenname</label>
                <input type="text" value={fahrtenname} id="fahrtenname" name="fahrtenname"
                       onChange={e => {
                           resetErrors();
                           setFahrtenname(e.target.value)
                       }}/>

                <label htmlFor="nachname">Nachname*</label>
                <input type="text" value={nachname} id="nachname" name="nachname"
                       className={errorInputIds.includes('nachname') ? 'validation-error' : undefined}
                       onBlur={validateInputs}
                       onChange={e => {
                           resetErrors();
                           setNachname(e.target.value)
                       }}/>

                <label htmlFor="mail">E-Mail*</label>
                <input type="email" value={mail} id="mail" name="mail"
                       className={errorInputIds.includes('mail') ? 'validation-error' : undefined}
                       onBlur={validateInputs}
                       onChange={e => {
                           resetErrors();
                           setMail(e.target.value)
                       }}/>

                <label htmlFor="handy">Handynummer*</label>
                <input type="tel" value={handy} id="handy" name="handy"
                       className={errorInputIds.includes('handy') ? 'validation-error' : undefined}
                       onBlur={validateInputs}
                       onChange={e => {
                           resetErrors();
                           if (e.target.value.startsWith("+49"))
                               setHandy(e.target.value.split("").filter(char => phoneChar.has(char)).join(""));
                           else
                               setHandy("+49");
                       }}/>

                <label htmlFor="verband">Verband*</label>
                <select id="verband" name="verband" value={verband}
                        className={errorInputIds.includes('verband') ? 'validation-error' : undefined}
                        onBlur={validateInputs}
                        onChange={e => {
                            resetErrors();
                            setVerband(e.target.value)
                        }}>
                    <option value="">Bitte auswählen...</option>
                    {VERBAENDE.map(elem => <option key={elem.short}
                                                   value={elem.short}>{isMobile ? elem.short : (elem.short + ' - ' + elem.long)}</option>)}
                    <option value="Andere">Andere</option>
                </select>

                {verband === "Andere" && <>
                    <label htmlFor="verbandName">Name des Verbands*</label>
                    <input type="text" value={verbandName} id="verbandName" name="verbandName"
                           className={errorInputIds.includes('verbandName') ? 'validation-error' : undefined}
                           onBlur={validateInputs}
                           onChange={e => {
                               resetErrors();
                               setVerbandName(e.target.value)
                           }}/>
                </>}

                <label htmlFor="gruppe">Name der Gruppe</label>
                <input type="text" value={gruppe} id="gruppe" name="gruppe" placeholder="z.B. Stamm XY"
                       onChange={e => {
                           resetErrors();
                           setGruppe(e.target.value)
                       }}/>

                {MAX_KANADIER >= 1 && <>
                    <label htmlFor="anzahlKanadier">Anzahl der gewünschten Kanadier (2-3 Personen pro Boot)*</label>
                    <select id="anzahlKanadier" name="anzahlKanadier" value={anzahlKanadier}
                            className={datumCheckResult && anzahlKanadier.localeCompare("0") !== 0 ? "no-bottom-margin" : ""}
                            onChange={e => {
                                resetErrors();
                                setAnzahlKanadier(e.target.value)
                            }}>
                        {[...Array(MAX_KANADIER + 1).keys()].map(elem => <option key={elem}
                                                                                 value={elem}>{elem}</option>)}
                    </select>
                    {anzahlKanadier.localeCompare("0") !== 0 && datumCheckResult && ((!datumCheckResult.concurrentBookingExists) || (datumCheckResult.kanadier && (+datumCheckResult.kanadier + +anzahlKanadier))) <= MAX_KANADIER &&
                        <p id="kanadier-hinweis"> ✅ Diese Anzahl an Kanadiern ist am gewählten Tag noch verfügbar.</p>}
                    {anzahlKanadier.localeCompare("0") !== 0 && datumCheckResult && datumCheckResult.concurrentBookingExists && ((datumCheckResult.kanadier === undefined) || ((+datumCheckResult.kanadier + +anzahlKanadier) > MAX_KANADIER)) &&
                        <div id="kanadier-hinweis"><p> ⚠️ Diese Anzahl an Kanadiern ist am gewählten Tag möglicherweise
                            nicht
                            verfügbar. Du erhältst nach der Buchung per Mail Bescheid, ob wir deinen Wunsch erfüllen
                            können.{(datumCheckResult.kajaks !== undefined && (MAX_KANADIER - +datumCheckResult.kanadier) !== 0) && <> Garantiert
                                verfügbar {(MAX_KANADIER - +datumCheckResult.kanadier) === 1 ? "ist" : "sind"} an diesem
                                Tag <b>{MAX_KANADIER - +datumCheckResult.kanadier}</b> Kanadier.</>}</p>
                        </div>}
                </>}

                {MAX_KAJAKS >= 1 && <>
                    <label htmlFor="anzahlKajaks">Anzahl der gewünschten Kajaks (1 Person pro Boot)*</label>
                    <select id="anzahlKajaks" name="anzahlKajaks" value={anzahlKajaks}
                            className={datumCheckResult && anzahlKajaks.localeCompare("0") !== 0 ? "no-bottom-margin" : ""}
                            onChange={e => {
                                resetErrors();
                                setAnzahlKajaks(e.target.value)
                            }}>
                        {[...Array(MAX_KAJAKS + 1).keys()].map(elem => <option
                            key={elem} value={elem}>{elem}</option>)}                </select>
                    {anzahlKajaks.localeCompare("0") !== 0 && datumCheckResult && ((!datumCheckResult.concurrentBookingExists) || (datumCheckResult.kajaks && (+datumCheckResult.kajaks + +anzahlKajaks))) <= MAX_KAJAKS &&
                        <div id="kajaks-hinweis"><p> ✅️ Diese Anzahl an Kajaks ist am gewählten Tag noch verfügbar.</p>
                        </div>}
                    {anzahlKajaks.localeCompare("0") !== 0 && datumCheckResult && datumCheckResult.concurrentBookingExists && ((datumCheckResult.kajaks === undefined) || ((+datumCheckResult.kajaks + +anzahlKajaks) > MAX_KAJAKS)) &&
                        <div id="kajaks-hinweis"><p> ⚠️ Diese Anzahl an Kajaks ist am gewählten Tag möglicherweise nicht
                            verfügbar. Du erhältst nach der Buchung per Mail Bescheid, ob wir deinen Wunsch erfüllen
                            können. {(datumCheckResult.kajaks !== undefined && (MAX_KAJAKS - +datumCheckResult.kajaks) !== 0) && <> Garantiert
                                verfügbar {(MAX_KAJAKS - +datumCheckResult.kajaks) === 1 ? "ist" : "sind"} am gewählten
                                Tag <b>{MAX_KAJAKS - +datumCheckResult.kajaks}</b> {(MAX_KAJAKS - +datumCheckResult.kajaks) === 1 ? "Kajak" : "Kajaks"}</>}</p>
                        </div>}
                </>}


                {datumCheckResult && ((anzahlKanadier.localeCompare("0") !== 0 && datumCheckResult && datumCheckResult.concurrentBookingExists && ((datumCheckResult.kanadier === undefined) || ((+datumCheckResult.kanadier + +anzahlKanadier) > MAX_KANADIER))) || (anzahlKajaks.localeCompare("0") !== 0 && datumCheckResult && datumCheckResult.concurrentBookingExists && ((datumCheckResult.kajaks === undefined) || ((+datumCheckResult.kajaks + +anzahlKajaks) > MAX_KAJAKS)))) && datumCheckAlternatives &&
                    <div className="alert alert-warning"><p>Die ausgewählte
                        Anzahl {(anzahlKanadier !== "0" && anzahlKajaks !== "0") ? "Kanadiern und Kajaks" : ((anzahlKanadier !== "0") ? "Kanadier" : "Kajaks")} wäre
                        z.B. an folgenden Tagen
                        noch frei:</p>
                        <ul>
                            {Object.entries(datumCheckAlternatives).filter(([date, value]) => ((!value.concurrentBookingExists) || (((value.kanadier && (+value.kanadier + +anzahlKanadier)) <= MAX_KANADIER) && ((value.kajaks && (+value.kajaks + +anzahlKajaks)) <= MAX_KAJAKS)))).map(([date, value]) =>
                                <li key={date}>{new Date(date).toLocaleDateString('de-DE', {
                                    weekday: 'long',
                                    month: 'long',
                                    day: 'numeric'
                                })}</li>)}
                        </ul>
                        {(datumCheckResult.kajaks !== undefined && datumCheckResult.kanadier !== undefined && (((MAX_KANADIER - +datumCheckResult.kanadier) !== 0 && anzahlKanadier !== "0") || ((MAX_KAJAKS - +datumCheckResult.kajaks) !== 0 && anzahlKajaks !== "0"))) ?
                            <p>Es würde uns sehr helfen wenn
                                du im darunterliegenden Feld angibst, ob
                                euch {anzahlKanadier !== "0" &&
                                    <b>{Math.min((MAX_KANADIER - +datumCheckResult.kanadier), anzahlKanadier) > 0 ? Math.min((MAX_KANADIER - +datumCheckResult.kanadier), anzahlKanadier) : "keine"} Kanadier </b>}{(anzahlKanadier !== "0" && anzahlKajaks !== "0") && "und "}{anzahlKajaks !== "0" &&
                                    <b>{Math.min((MAX_KAJAKS - +datumCheckResult.kajaks), anzahlKajaks) > 0 ? Math.min((MAX_KAJAKS - +datumCheckResult.kajaks), anzahlKajaks) : "keine"} {Math.min((MAX_KAJAKS - +datumCheckResult.kajaks), anzahlKajaks) === 1 ? "Kajak" : "Kajaks"}</b>} ausreichen
                                würden bzw.
                                ein Alternativtermin
                                passen könnte. Wir versuchen dann, das beste für euch rauszuholen.</p>
                            : <p>Es würde uns sehr helfen wenn du im darunterliegenden Feld angibst, ob euch ein
                                Alternativtermin passen würde.</p>}
                    </div>}

                {MAX_SUPS >= 1 && <>
                    <label htmlFor="anzahlSups">Anzahl der gewünschten Stand-up-Paddling-Boards*</label>
                    <select id="anzahlSups" name="anzahlSups" value={anzahlSups}
                            className={datumCheckResult && anzahlSups.localeCompare("0") !== 0 ? "no-bottom-margin" : ""}
                            onChange={e => {
                                resetErrors();
                                setAnzahlSups(e.target.value)
                            }}>
                        {[...Array(MAX_SUPS + 1).keys()].map(elem => <option key={elem}
                                                                             value={elem}>{elem}</option>)}
                    </select>
                    {anzahlSups.localeCompare("0") !== 0 && datumCheckResult && ((!datumCheckResult.concurrentBookingExists) || (datumCheckResult.sups && (+datumCheckResult.sups + +anzahlSups))) <= MAX_SUPS &&
                        <div id="sups-hinweis"><p> ✅️ Diese Anzahl an SUP-Boards ist am gewählten Tag noch
                            verfügbar.</p>
                        </div>}
                    {anzahlSups.localeCompare("0") !== 0 && datumCheckResult && datumCheckResult.concurrentBookingExists && ((datumCheckResult.sups === undefined) || ((+datumCheckResult.sups + +anzahlSups) > MAX_SUPS)) &&
                        <div id="sups-hinweis"><p> ⚠️ Diese Anzahl an SUP-Boards ist am gewählten Tag möglicherweise
                            nicht
                            verfügbar. Du erhältst nach der Buchung per Mail Bescheid, ob wir deinen Wunsch erfüllen
                            können. {(datumCheckResult.sups !== undefined && (MAX_SUPS - +datumCheckResult.sups) !== 0) && <> Garantiert
                                verfügbar {(MAX_SUPS - +datumCheckResult.sups) === 1 ? "ist" : "sind"} am gewählten
                                Tag <b>{MAX_SUPS - +datumCheckResult.sups}</b> {(MAX_SUPS - +datumCheckResult.sups) === 1 ? "SUP-Board" : "SUP-Boards"}</>}</p>
                        </div>}
                </>}

                <label htmlFor="verteiler">Jo, ich möchte zum AHP-Aktionen-Verteiler hinzugefügt werden und in wenigen
                    Mails pro Jahr über Aktionen und Angebote der AHP informiert werden.</label>
                <input type="checkbox" id="verteiler" name="verteiler"
                       onChange={e => setVerteiler(!verteiler)}/>
                {verteiler && <> Danke 😃</>}

                <label htmlFor="anmerkungen">Möchtest du uns noch was sagen?</label>
                <textarea value={anmerkungen} rows={3} cols={45} id="anmerkungen" name="anmerkungen"
                          onChange={e => setAnmerkungen(e.target.value)}/>

                {MODE === 'ADMIN' && <>
                    <label htmlFor="bookingStatus">Status</label>
                    <select id="bookingStatus" value={bookingStatus}
                            onChange={(e) => setBookingStatus(e.target.value)}>
                        <option value='pending'>ausstehend</option>
                        <option value='approved'>Bestätigt</option>
                        <option value='approved-nomails'>Bestätigt (keine Mails senden)</option>
                    </select>
                </>}

                {errors.map(val => <div className="error">{val.text}</div>)}
                <button type="button" color="default" onClick={e => {
                    if (validateInputs(true)) {
                        if (handy.includes("+490") || handy.includes("+49 0"))
                            setHandy(handy.replace("+490", "+49 ").replace("+49 0", "+49 "));

                        switch (MODE) {
                            case 'ADMIN':
                                axios.post(BASEURL + "admin/add.php", {
                                    datum: multidayBooking ? undefined : datumConvBackend(datum),
                                    multidayBooking: multidayBooking,
                                    datumVon: multidayBooking ? datumConvBackend(datumVon) : undefined,
                                    datumBis: multidayBooking ? datumConvBackend(datumBis) : undefined,
                                    vorname: vorname.trim(),
                                    fahrtenname: fahrtenname.trim(),
                                    nachname: nachname.trim(),
                                    mail: mail.trim(),
                                    verband: verband === "Andere" ? verbandName : verband,
                                    gruppe: gruppe.trim(),
                                    anzahlKanadier: anzahlKanadier,
                                    anzahlKajaks: anzahlKajaks,
                                    anzahlSups: anzahlSups,
                                    beginnTsp: beginnTsp.trim(),
                                    endeTsp: endeTsp.trim(),
                                    anmerkungen: anmerkungen.trim(),
                                    handy: handy.trim(),
                                    status: bookingStatus,
                                }).then(response => {
                                    alert("Erfolgreich hinzugefügt");
                                });
                                break;
                            case 'USER':
                                setStatus(2);
                                break;
                        }
                    }

                }}>{MODE === 'USER' && <>Weiter</>}{MODE === 'ADMIN' && <>Hinzufügen</>}
                </button>
            </>}

            {status === 2 && <>
                <div style={{marginBottom: "1em"}}>
                    <p style={{marginBottom: "3em"}}>Bitte überprüfe deine Angaben:</p>
                    <p><b>Datum: </b> {fancyDatum(datum)}</p>
                    {(beginnTsp !== "" || endeTsp !== "") &&
                        <p>
                            <b>Zeitraum: </b> {beginnTsp !== "" ? beginnTsp : "0:00"} - {endeTsp !== "" ? endeTsp : "23:59"}
                        </p>}
                    {!(beginnTsp !== "" || endeTsp !== "") &&
                        <p><b>Zeitraum: </b> gesamter Tag</p>}
                    <p><b>Name:</b> {vorname} {nachname}</p>
                    <p><b>Fahrtenname:</b> {fahrtenname}</p>
                    <p><b>E-Mail:</b> {mail}</p>
                    <p><b>Handynummer:</b> {handy}</p>
                    <p><b>Verband:</b> {verband}</p>
                    <p><b>Gruppe:</b> {gruppe}</p>
                    <p><b>Anzahl Kanadier:</b> {anzahlKanadier}</p>
                    <p><b>Anzahl Kajaks:</b> {anzahlKajaks}</p>
                    <p><b>Anzahl SUP-Boards:</b> {anzahlSups}</p>
                    <p><b>Anmerkungen:</b></p>
                    <p style={{marginBottom: "3em"}}>{anmerkungen}</p>
                    <p>Sind die Angaben richtig?</p>
                    <button type="button" className="button-inline" style={{marginRight: ".5em"}} onClick={e => {
                        setStatus(1);
                    }}>Nein
                    </button>
                    <button type="button" disabled={isLoading} className="button-inline" onClick={e => {
                        setIsLoading(true);
                        axios.post(BASEURL + "1.php", {
                            handy: handy.trim().replaceAll("+", "").replaceAll(" ", ""),
                            mail: mail.trim()
                        }).then(response => {
                            setBackendErrorOnStatus2(undefined);
                            setId(response.data.id);
                            if (response.data.verificationNeeded) {
                                setStatus(3);
                                setIsLoading(false);
                            } else {
                                axios.post(BASEURL + "2-md.php", {
                                    code: code,
                                    datum: multidayBooking ? undefined : datumConvBackend(datum),
                                    multidayBooking: multidayBooking,
                                    datumVon: multidayBooking ? datumConvBackend(datumVon) : undefined,
                                    datumBis: multidayBooking ? datumConvBackend(datumBis) : undefined,
                                    vorname: vorname.trim(),
                                    fahrtenname: fahrtenname.trim(),
                                    nachname: nachname.trim(),
                                    mail: mail.trim(),
                                    verband: verband === "Andere" ? verbandName : verband,
                                    gruppe: gruppe.trim(),
                                    anzahlKanadier: anzahlKanadier,
                                    anzahlKajaks: anzahlKajaks,
                                    anzahlSups: anzahlSups,
                                    beginnTsp: beginnTsp.trim(),
                                    endeTsp: endeTsp.trim(),
                                    anmerkungen: anmerkungen.trim(),
                                    handy: handy.trim().replaceAll("+", "").replaceAll(" ", ""),
                                    verteiler: verteiler

                                }).then(response => {
                                    setStatus(4);
                                    setIsLoading(false);
                                    saveToLocalStorage();
                                }).catch(e => {
                                    setBackendErrorOnStatus2({axiosError: e, tsp: new Date()});
                                    reportBackendError(e);
                                }).finally(() => {
                                    setIsLoading(false);
                                });
                            }
                        }).catch(e => {
                            reportBackendError(e);
                            setBackendErrorOnStatus2({axiosError: e, tsp: new Date()});
                        }).finally(() => {
                            setIsLoading(false);
                        });
                    }}>Ja
                    </button>
                    {backendErrorOnStatus2 && <div className="alert alert-warning">
                        <p>Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal oder sende uns deine
                            Buchungsdaten per Mail. Kontaktdaten findest du auf <a
                                href="https://www.a-h-p.de/fuer-die-verbaende/kanus-ausleihen/">dieser
                                Seite</a>.</p>
                        <p>{backendErrorOnStatus2.axiosError.toString()}</p>
                        <p>{backendErrorOnStatus2.tsp.toString()}</p>
                    </div>}
                </div>
            </>}

            {status === 3 && <>
                <div style={{marginBottom: "1em"}}>Bitte gib den Bestätigungscode ein, den du per SMS erhalten hast.
                </div>
                <label htmlFor="code">Code*</label>
                <input type="text" value={code} id="code" name="code" onChange={e => {
                    setCode(e.target.value)
                }}/>
                {codeError && <div className="error">
                    <p>Leider ist die Verifikation fehlgeschlagen. Gebe bitte den Code erneut ein.</p>
                    {backendErrorCountOnStatus3 >= 3 && <p>
                        Falls du sicher bist, dass der Code richtig ist, liegt wohl ein technisches Problem vor.
                        Bitte versuche es später noch einmal oder sende uns deine
                        Buchungsdaten per Mail. Kontaktdaten findest du auf <a
                        href="https://www.a-h-p.de/fuer-die-verbaende/kanus-ausleihen/">dieser
                        Seite</a>.
                    </p>}
                </div>}
                <button type="button" disabled={isLoading} onClick={e => {
                    setIsLoading(true);
                    axios.post(BASEURL + "2-md.php", {
                        id: id,
                        code: code,
                        datum: multidayBooking ? undefined : datumConvBackend(datum),
                        multidayBooking: multidayBooking,
                        datumVon: multidayBooking ? datumConvBackend(datumVon) : undefined,
                        datumBis: multidayBooking ? datumConvBackend(datumBis) : undefined,
                        vorname: vorname.trim(),
                        fahrtenname: fahrtenname.trim(),
                        nachname: nachname.trim(),
                        mail: mail.trim(),
                        verband: verband === "Andere" ? verbandName : verband,
                        gruppe: gruppe.trim(),
                        anzahlKanadier: anzahlKanadier,
                        anzahlKajaks: anzahlKajaks,
                        anzahlSups: anzahlSups,
                        beginnTsp: beginnTsp.trim(),
                        endeTsp: endeTsp.trim(),
                        anmerkungen: anmerkungen.trim(),
                        verteiler: verteiler

                    }).then(response => {
                        setStatus(4);
                        saveToLocalStorage();
                        setBackendErrorCountOnStatus3(0);
                    }).catch(e => {
                        setCodeError(true);
                        setCode("");
                        setBackendErrorCountOnStatus3(backendErrorCountOnStatus3 + 1);
                        reportBackendError(e);
                    }).finally(() => {
                        setIsLoading(false);
                    });
                }}>Weiter
                </button>
            </>}

            {status === 4 && <>
                <div style={{marginBottom: "1em"}}><p>Danke für deine Buchung. Du erhältst in Kürze eine Bestätigung per
                    E-Mail.</p>
                    <p>Solltest du diese Mail nicht in deinem Posteingang finden, überprüfe bitte deinen Spam-Ordner.
                        Wenn auch dort keine Mail zu finden ist, könnte es sein, dass du evtl. eine falsche Mailadresse
                        eingegeben hast. Bitte schicke uns dann eine kurze Mail. Kontaktdaten findest du auf <a
                            href="https://www.a-h-p.de/fuer-die-verbaende/kanus-ausleihen/">dieser
                            Seite</a>.</p>
                    <button type="button" onClick={(e) => {
                        setDatum("");
                        setBackendErrorCountOnStatus3(0);
                        setBackendErrorOnStatus2(undefined);
                        setCode('')
                        setCodeError(false);
                        setStatus(1);
                    }}>Weitere Buchung erstellen
                    </button>
                </div>
            </>}
            <hr/>
            <div className="container space-between">
                <a className="no-underline" href="https://www.a-h-p.de/kontakt/">Kontakt</a>
                <a className="no-underline" href="https://www.a-h-p.de/impressum/">Impressum</a>
                <a className="no-underline" href="https://www.a-h-p.de/datenschutzerklaerung/">Datenschutzerklärung</a>
            </div>
        </div>
    );
}

export default App;
